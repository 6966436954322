export const english = {
  language: "english",

  indexTitle: "Zen Altitude || Les Bellville",
  metaDescription:
    "Massages and spa treatments. Val Thorens, Les Menuires and Saint Martin de Belleville. Fully adapted to your needs, the personalized massage allows you to release physical tensions while releasing your psyche, the objective being to refocus you within your body. Compose your massage as you wish. We will agree together on which massage will be most appropriate for you.",
  metaKeywords:
    "Massages at home, Val Thorens, Les Belleville, massage, facials, leg care",

  soinVisagePageTitle: "Facials || Zen Altitude",
  massagePageTitle: "Massages || Zen Altitude",
  pricePageTitle: "Price list || Zen Altitude",

  metaDescriptionSoinVisage:
    "Facials for women and men which are intended to preserve the cleanliness of your face, to beautify it, firm it, tone it or regenerate it.",
  metaDescriptionPrice: "Price list Zen Altitude",
  metaDescriptionMassage:
    "Fully adapted to your needs, the personalized massage allows you to release physical tensions while releasing your psyche, the objective being to refocus you within your body. Compose your massage as you wish. We will agree together on which massage will be most appropriate for you.",
  soins: "Treatments",
  prices: "Prices",

  heroTitle: "ZEN ALTITUDE",
  heroTitle2: "Massages and spa treatments at home",
  heroText:
    "Give yourself a moment of serenity and pure relaxation. Take the time to recharge your batteries!",

  welcome: "Welcome to Zen Altitude ",
  introText1:
    "Spa professional since 14 years, I offer you my massages and treatments at home.",
  introText2:
    "I travel with all the necessary equipment to create a really relaxing space. I can also welcome you in Val Thorens (at my home) where a massage cabin is entirely dedicated to you.",
  introText3:
    "Please do not hesitate to contact me if you would like more information.",

  product1Title: "Personalized massage",
  product1Text:
    "Fully adapted to your needs, the personalized massage allows you to release physical tensions while releasing your psyche, the objective being to refocus you within your body. Compose your massage as you wish. We will agree together on which massage will be most appropriate for you.",

  product2Title: "Revitalizing treatment for tired legs",
  product2Text1:
    "30-minute leg massage + clay/cryo wrap and application of specific products. Provides immediate relief from joint and muscle pain thanks to its cold effect.",
  product2Li1: "-Improves circulation",
  product2Li2: "- Makes bruises fade faster",
  product2Li3: "- Facilitates drainage and muscle relaxation",
  product2Text2: "Duration of treatment: 60 minutes.",

  product3Title: "Facials",
  product3Text:
    "Facials for women and men which are intended to preserve the cleanliness of your face, to beautify it, firm it, tone it or regenerate it.",

  moreInfo: "More information ",
  priceList: "Price list",

  generalMassageTitle: "Sport or relaxing massage..",
  generalMassageText:
    "Compose your massage as you wish. The massages are fully personalized, we will agree together on the massage that is most appropriate for you. My massage oils are organic and are adapted according to your needs.",
  pregnantTitle: "Pregnancy MASSAGE",
  pregnantText1:
    "Specially designed for pregnant women, starting from the 4th month of pregnancy, prenatal massage offers a real moment of relaxation and comfort for both the mother and the baby.",
  pregnantText2:
    "Relieves muscle tension and fatigue, heavy legs, back pain, improves blood circulation.",
  childTitle: "Child MASSAGE  ( 5 - 12 years )",
  childText:
    "Gentle and light maneuvers. Allows the child to be calmer and more concentrated, secures and promotes sleep, promotes self-esteem, improves management of emotions.",
  women: "Women",
  men: "Men",

  soinVisageTitle: "Facials",
  soinVisageFemmeTitle: "Facials Women",

  soinVisageFemme1: "Belle nigelle",
  soinVisageFemme2: "Soin ses'âme",
  soinVisageFemme3: "Secret of youth",
  soinVisageFemme4: "Oriental beauty purifying treatment",
  soinVisageFemme5: "Radiance boost treatment",
  soinVisageFemme6: "Focus regard",
  soinVisageFemme7: "Collagen mask",
  soinVisageFemme7UnderTitle:
    "(Can be included in a facial treatment of your choice for women or men)",
  soinVisageFemme1Price: "60 minutes : 80€",
  soinVisageFemme2Price: "60 minutes : 80€",
  soinVisageFemme3Price: "60 minutes : 80€",
  soinVisageFemme4Price: "60 minutes : 80€",
  soinVisageFemme5Price: "60 minutes : 80€",
  soinVisageFemme6Price: "25 minutes : 50€",
  soinVisageFemme7Price1: "Full face 20 minutes : 45€",
  soinVisageFemme7Price2: "Eye contour 20 minutes : 35€",

  soinVisageFemme1Effects: "Sooting - hydrating - unifying",
  soinVisageFemme2Effects: "Repairing - nourishing - hydrating",
  soinVisageFemme3Effects: "Restructuring - repairing - nourishing",
  soinVisageFemme4Effects: "Purifying - oxygenating - cleansing",
  soinVisageFemme5Effects: "Radiant - oxygenating - unifying",
  soinVisageFemme6Effects: "Redensifying - energizing - protective",

  soinVisageFemme1SkinType: "Sensitive and reactive skin",
  soinVisageFemme2SkinType: "Dry skin",
  soinVisageFemme3SkinType: "Dry and dull skin",
  soinVisageFemme4SkinType: "Mixed to oily skin",
  soinVisageFemme5SkinType: "All skin types",

  soinVisageFemme1Description:
    "An exclusive oriental charm treatment for sensitive skin based on nigella oil",
  soinVisageFemme2Description:
    "In India, sesame is used to soothe the mind and give a feeling of well-being",
  soinVisageFemme3Description:
    "The most precious beauty treatment to keep skin texture smooth, firm and luminous.",
  soinVisageFemme4Description:
    "For asphyxiated skin in order to regain clear skin and a smooth skin texture",
  soinVisageFemme7Description1:
    "Hydratation extraordinaire et apaisement remarquable de la peau - réduction des irritations de la peau dues à l'exposition au soleil, l'épilation - soin restructurant et régénérant - effet bénéfique dans le traitement des rides - la peau est remarquablement douce.",
  soinVisageFemme7Description2:
    "Skin types: all and more particularly irritated, sensitive, dry, dehydrated and senescent skin",
  soinVisageHommeTitle: "FACIALS men",

  soinVisageHomme1: "BARBER'S CARE ",
  soinVisageHomme2: "L’HOMME",
  soinVisageHomme3: "BLACK CUMIN ",

  soinVisageHomme1Price: "25 MINUTES : 50€",
  soinVisageHomme2Price: "60 MINUTES : 80€",
  soinVisageHomme3Price: "60 MINUTES : 80€",

  soinVisageHomme1Description:
    "Face care for a frech radiant face and a soft beard.",
  soinVisageHomme2Description:
    "Care for all skin types, cleaning and moisturizing",
  soinVisageHomme3Description:
    "Care for sensitive and reactive skin, soothing and moisturizing",

  giftVoucher: "Offer a gift voucher",
  giftVoucherIntro: "",

  travelExpenses: "Travel expenses",

  massage: "Massage",
  legs: "Legs",
  face: "Facials",

  contact: "Contact",
  contactIntro: "Contact intro",
  name: "Name",
  email: "Email",
  message: "Message",
  send: "Send",

  thanks: "Thank you for your message!",

  cookieHeader: "WE VALUE YOUR PRIVACY!",
  cookieText: "This website uses cookies to analyze our traffic.",
  cookieAccept: "I accept!",
  cookieDecline: "I decline..",

  rights: "All rights reserved.",
  legal: "Disclaimer",
};
