exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-massages-jsx": () => import("./../../../src/pages/en/massages.jsx" /* webpackChunkName: "component---src-pages-en-massages-jsx" */),
  "component---src-pages-en-price-list-jsx": () => import("./../../../src/pages/en/price-list.jsx" /* webpackChunkName: "component---src-pages-en-price-list-jsx" */),
  "component---src-pages-en-soin-visage-jsx": () => import("./../../../src/pages/en/soin-visage.jsx" /* webpackChunkName: "component---src-pages-en-soin-visage-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-massages-jsx": () => import("./../../../src/pages/massages.jsx" /* webpackChunkName: "component---src-pages-massages-jsx" */),
  "component---src-pages-price-list-jsx": () => import("./../../../src/pages/price-list.jsx" /* webpackChunkName: "component---src-pages-price-list-jsx" */),
  "component---src-pages-soin-visage-jsx": () => import("./../../../src/pages/soin-visage.jsx" /* webpackChunkName: "component---src-pages-soin-visage-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

